import { observable, action, computed, makeObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class AuthStore {
    user = null;

    constructor() {
        makeObservable(this, {
            user: observable,
            setUser: action,
            logout: action,
            isLoggedIn: computed,
            checkAuthRedirect: action,
        })

        makePersistable(this, { name: 'authStore', properties: ['user'], storage: window.localStorage });
    }

    setUser = (user) => {
        this.user = user;
    };

    checkAuthRedirect = (navigate) => {
        if (this.isLoggedIn === false)
            navigate('/login');
    };

    login = (user, navigate) => {
        this.setUser(user)
        navigate('/');
    }

    get isLoggedIn() {
        return (this.user !== null) ? true : false;
    }

    logout = () => {
        this.user = null;
    }


}

const authStore = new AuthStore()

export default authStore;