import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Col, Row } from 'react-bootstrap';
import { CardLayout } from '../../components/cards';
import { Breadcrumb, Pagination } from '../../components';
import data from "../../data/product/list.json";
import { Anchor } from '../../components/elements';
import { LabelField } from '../../components/fields';
import { ProductsTable } from '../../components/tables';

function ProductIndex(props) {
    return (
        <PageLayout title="Danh sách sản phẩm">
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle}>
                            {data?.breadcrumb.map((item, index) => (
                                <li key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </li>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Row>
                            {data?.product.filter.map((item, index) => (
                                <Col xs={12} sm={6} md={4} lg={3} key={index}>
                                    <LabelField
                                        type={item.type}
                                        label={item.label}
                                        option={item.option}
                                        placeholder={item.placeholder}
                                        labelDir="label-col"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                            ))}
                            <Col xl={12}>
                                <ProductsTable
                                    thead={data?.product.thead}
                                    tbody={data?.product.tbody}
                                />
                                <Pagination />
                            </Col>
                        </Row>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}

export default ProductIndex;