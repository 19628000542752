import PageLayout from '../../layouts/PageLayout';
import { Box, Button } from '../../components/elements';
import { Row } from 'react-bootstrap';
import { CardLayout } from '../../components/cards';
import { DivideTitle } from '../../components';
import Calendal from './Calendal';
import { useState } from 'react';
import CalendalDetail from './CalendalDetail';

function TaskIndex(props) {
    const [datetask, setDatetask] = useState();

    const showDetailsHandle = (cloneDay, dayStr) => {
        console.log('showDetailsHandle called', cloneDay, dayStr);
        setDatetask(cloneDay);
    }
    return (
        <PageLayout>
            <CardLayout className="p-sm-3">
                <Box className="mb-3">
                    <DivideTitle title="Công việc của Tuần" className="mb-3" />
                    <Calendal showDetailsHandle={showDetailsHandle} />
                </Box>
                <Box className="mb-3">
                    <DivideTitle title={`Công việc của ngày: ${datetask}`} className="mb-3" />
                    <Button className="btn btn-primary m-2">Khởi Tạo</Button>
                    <CalendalDetail />
                </Box>
            </CardLayout>
        </PageLayout>
    );
}

export default TaskIndex;