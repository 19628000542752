import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Analytics, BlankPage, CRM, Ecommerce, InvoiceDetails, InvoiceList, Message, MyAccount, Notification, OrderList, ProductList, ProductUpload, ProductView, Settings, UserList, UserProfile } from '../pages/master';
import { Alerts, Avatars, Buttons, Charts, Colors, Fields, Headings, Tables } from '../pages/blocks';
import { ChangeLog, Documentation, Error, Overview } from '../pages/supports';
import { CustomerItem, CustomerList } from '../pages/customer';
import { Dashboard } from '../pages/dashboard';
import { ForgotPassword, Login, Profile, Register } from '../pages/auth';
import { SaleIndex } from '../pages/sale';
import { ReportIndex } from '../pages/reports';
import { ProductIndex } from '../pages/product';
import TaskIndex from '../pages/task/TaskIndex';

function RouterHome(props) {
    return (
        <Routes>
            {/* template  */}
            <Route path="/blank-page" element={<BlankPage />} />

            {/* dashboard  */}
            <Route path="/" element={<Dashboard />} />



            {/* guide Pages */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />



            {/* Module Customer */}
            <Route path="customer" element={<CustomerList />} />
            <Route path="customer/:id" element={<CustomerItem />} />


            {/* Module Customer */}
            <Route path="products" element={<ProductIndex />} />
            <Route path="products/:id" element={<ProductIndex />} />

            {/* Module Sale */}
            <Route path="sale" element={<SaleIndex />} />

            {/* Module task */}
            <Route path="task" element={<TaskIndex />} />

            {/* Module report */}
            <Route path="report" element={<ReportIndex />} />

            {/* master Pages auth */}
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/crm" element={<CRM />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/product-view" element={<ProductView />} />
            <Route path="/product-upload" element={<ProductUpload />} />
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/invoice-details" element={<InvoiceDetails />} />
            <Route path="/order-list" element={<OrderList />} />
            <Route path="/message" element={<Message />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/settings" element={<Settings />} />


            {/* Blocks Pages */}
            <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/alerts" element={<Alerts />} />

            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/changelog" element={<ChangeLog />} />
        </Routes>
    );
}

export default RouterHome;