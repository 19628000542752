import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Box, Icon, Text } from '../../components/elements';

function SaleIndex(props) {
    return (
        <PageLayout title="Bán hàng">
            <Box className="mc-blank">
                <Icon type="extension" />
                <Text>blank this page</Text>
            </Box>
        </PageLayout>
    );
}

export default SaleIndex;