import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Col, Row } from 'react-bootstrap';
import data from "../../data/dashboard/data.json";
import { ActivityCard, ClientsCard, EcommerceCard, OrdersCard, SalesCard } from '../../components/cards';

function Dashboard(props) {
    return (
        <PageLayout title="Dashboard">
            <Row>
                <Col xs={12} xl={8}>
                    <Row xs={1} sm={2}>
                        {data?.heros?.map((item, index) => (
                            <Col key={index}>
                                <EcommerceCard
                                    icon={item.icon}
                                    trend={item.trend}
                                    title={item.title}
                                    number={item.number}
                                    variant={item.variant}
                                    percent={item.percent}
                                    compare={item.compare}
                                    // dotsMenu={item.dotsMenu}
                                    dotsMenu={null}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={12} xl={4}>
                    <SalesCard
                        title={data?.sales.title}
                        amount={data?.sales.amount}
                        percent={data?.sales.percent}
                        trendIcon={data?.sales.trendIcon}
                        // dotsMenu={data?.sales.dotsMenu}
                        dotsMenu={null}
                        compare={data?.sales.compare}
                        chart={data?.sales.chart}
                    />
                </Col>
                <Col xl={3}>
                    <OrdersCard
                        title={data?.orders.title}
                        dotsMenu={data?.orders.dotsMenu}
                        items={data?.orders.items}
                    />
                </Col>
                <Col xl={5}>
                    <ClientsCard
                        title={data?.clients.title}
                        dotsMenu={data?.clients.dotsMenu}
                        table={data?.clients.table}
                    />
                </Col>
                <Col xl={4}>
                    <ActivityCard
                        title={data?.activity.title}
                        dotsMenu={data?.activity.dotsMenu}
                        items={data?.activity.items}
                    />
                </Col>
            </Row>
        </PageLayout>
    );
}

export default Dashboard;