import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";

import RouterHome from "./routers";

export default function App() {
    return (
        <HelmetProvider>
            <ThemeProvider>
                <LoaderProvider>
                    <BrowserRouter>
                        <RouterHome />
                    </BrowserRouter>
                </LoaderProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
}

