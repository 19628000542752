import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from 'react-helmet-async';

import Main from "./Main";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import authStore from "../stores/authStore";


function PageLayout(props) {

    // const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        authStore.checkAuthRedirect(navigate)
    }, [navigate])

    return (
        <>
            <Helmet>
                <title>{props.title || 'Bevita CRM System'}</title>
            </Helmet>
            <DrawerProvider>
                <Header />
                <Sidebar />
                <Main>
                    <>
                        {props.children}
                        {/* {location.pathname !== "/message" ? <Footer /> : ""} */}
                    </>
                </Main>
            </DrawerProvider>
        </>
    )
}

export default observer(PageLayout)