import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Box } from '../../components/elements';
import { CardHeader } from '../../components/cards';

function CalendalDetail(props) {
    return (
        <Row>
            <Col xl={4}>
                <Box className="mc-card">
                    <CardHeader title="Chưa xử lý" />

                </Box>
            </Col>
            <Col xl={4}>
                <Box className="mc-card">
                    <CardHeader title="Đang xử lý" />

                </Box>
            </Col>
            <Col xl={4}>
                <Box className="mc-card">
                    <CardHeader title="Đã xử lý" />
                </Box>
            </Col>
        </Row>
    );
}

export default CalendalDetail;