import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import { Button, Heading } from '../../components/elements';

function Calendal(props) {
    const dateFormat = "MMM yyyy";

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState(new Date());

    const onDateClickHandle = (day, dayStr) => {
        setSelectedDate(day);
        props.showDetailsHandle(dayStr);
    };

    const changeWeekHandle = (btnType) => {
        //console.log("current week", currentWeek);
        if (btnType === "prev") {
            //console.log(subWeeks(currentMonth, 1));
            setCurrentMonth(subWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        }
        if (btnType === "next") {
            //console.log(addWeeks(currentMonth, 1));
            setCurrentMonth(addWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        }
    };

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <th key={i}>{format(addDays(startDate, i), dateFormat)}</th>
            );
        }
        return (
            <thead>
                <tr>
                    <th></th>
                    {days}
                    <th></th>
                </tr>
            </thead>
        );
    };

    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <td className={`${isSameDay(day, new Date())
                        ? "text-capitalize h2"
                        : isSameDay(day, selectedDate)
                            ? "text-capitalize h2"
                            : "text-capitalize h5"
                        }`}
                        onClick={() => {
                            const dayStr = format(cloneDay, "cccc dd-MM-yyyy");
                            onDateClickHandle(cloneDay, dayStr);
                        }}

                        key={day}
                    >
                        {formattedDate}
                    </td>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <tr key={day}>
                    <td>
                        <Button className="btn btn-primary m-1"
                            onClick={() => changeWeekHandle("prev")}>
                            Trước
                        </Button>
                    </td>
                    {days}
                    <td>
                        <Button className="btn btn-primary m-1"
                            onClick={() => changeWeekHandle("next")}> Sau</Button>
                    </td>
                </tr>
            );
            days = [];
        }
        return <tbody>{rows}</tbody>;
    };

    return (
        <>
            <Row>
                <Heading as="h4" className="text-capitalize">{currentWeek} - {format(currentMonth, dateFormat)}</Heading>
            </Row>
            <Row>
                <Table style={{ textAlign: "center", margin: "auto" }}>
                    {renderDays()}
                    {renderCells()}
                </Table>
            </Row>
        </>
    );
}

export default Calendal;