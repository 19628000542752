import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import { ActivityCard, CardHeader, CardLayout, FloatCard } from '../../components/cards';
import { Breadcrumb, DivideTitle, DuelText, RoundAvatar } from '../../components';
import { Anchor, Box, Button, Icon, Item, List, Text } from '../../components/elements';
import { LabelTextarea } from '../../components/fields';

import data from "../../data/customer/item.json";

function CustomerItem(props) {
    return (
        <PageLayout title="Chi tiết khách hàng">
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title="user profile">
                            {data?.breadcrumb.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={3}>
                    <CardLayout>
                        <CardHeader title="Chi tiết khách hàng" dotsMenu={data?.dotsMenu} />
                        <Box className="mc-user-group">
                            <Box className="mc-user-profile">
                                <RoundAvatar
                                    src={data?.profile.src}
                                    alt={data?.profile.alt}
                                    size={data?.profile.size}
                                />
                                <DuelText
                                    title={data?.profile.fullname}
                                    descrip={data?.profile.username}
                                    size={data?.profile.size}
                                />
                            </Box>
                            <Box className="mb-4">
                                <DivideTitle title="Người quản lý" />
                                <RoundAvatar src="/images/avatar/08.webp" alt="avatar" size="xs d-inline-flex m-2" />
                                <RoundAvatar src="/images/avatar/02.webp" alt="avatar" size="xs d-inline-flex m-2" />
                            </Box>
                            <Box className="mb-4">
                                <DivideTitle title="Thông tin liên hệ" className="mb-4" />
                                <List className="mc-user-metalist">
                                    {data?.contact.map((item, index) => (
                                        <Item key={index}>
                                            <Icon>{item.icon}</Icon>
                                            <Text as="span">{item.text}</Text>
                                        </Item>
                                    ))}
                                </List>
                            </Box>
                            <Box className="mb-4">
                                <DivideTitle title="Mô tả ngắn" className="mb-3" />
                                <Text className="mc-user-bio mb-4">{data?.bio.descrip}</Text>
                            </Box>
                            <Box>
                                <DivideTitle title="Nhóm khách hàng" className="mb-4" />
                                <Box className="mc-user-social">
                                    {data?.social.map((item, index) => (
                                        <Anchor
                                            key={index}
                                            href={item.path}
                                            text={item.type}
                                            iconClass={item.icon}
                                            className={item.type}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </CardLayout>
                </Col>
                <Col xl={9}>
                    <Row>
                        {data?.float.map((item, index) => (
                            <Col md={4} lg={4} key={index}>
                                <FloatCard
                                    variant={item.variant}
                                    digit={item.digit}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            </Col>
                        ))}
                        <Col xl={12}>
                            <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs m-2" transition={true}>
                                <Tab eventKey="profile" title="Lịch sử trao đổi" className="profile">
                                    <ActivityCard
                                        style={{ height: "540px" }}
                                        title={data?.activity.title}
                                        dotsMenu={data?.activity.dotsMenu}
                                        items={data?.activity.items}
                                    />
                                </Tab>
                                <Tab eventKey="task" title="Lịch làm việc" className=" profile">
                                    <CardLayout >
                                        <Row>
                                            <Col>
                                                <Button className="mc-btn primary">add</Button>
                                            </Col>
                                        </Row>
                                        <ActivityCard
                                            style={{ height: "540px" }}
                                            title={data?.activity.title}
                                            dotsMenu={data?.activity.dotsMenu}
                                            items={data?.activity.items}
                                        />
                                    </CardLayout>
                                </Tab>
                                <Tab eventKey="pddt" title="Phác đồ điều trị" className=" profile">

                                    <ActivityCard
                                        style={{ height: "540px" }}
                                        title={data?.activity.title}
                                        dotsMenu={data?.activity.dotsMenu}
                                        items={data?.activity.items}
                                    />
                                </Tab>
                                <Tab eventKey="oder" title="Đơn hàng" className=" profile">

                                    <ActivityCard
                                        style={{ height: "540px" }}
                                        title={data?.activity.title}
                                        dotsMenu={data?.activity.dotsMenu}
                                        items={data?.activity.items}
                                    />
                                </Tab>
                                <Tab eventKey="notes" title="Ghi Chú" className=" profile">

                                    <CardLayout >
                                        <Row>
                                            <Col xl={12}>
                                                <LabelTextarea label="Ghi chú" fieldSize="w-100 h-text-md" />
                                                <Button className="mc-btn primary">add</Button>
                                            </Col>

                                            <Col xl={12}>
                                                Danh sach cac ghi chu
                                            </Col>
                                        </Row>
                                    </CardLayout>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageLayout>
    );
}

export default CustomerItem;